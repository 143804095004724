var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-list',{staticClass:"class_faultlist",attrs:{"size":"small","data-source":_vm.data,"pagination":{
    position: 'bottom',
    size: 'small',
    style: { marginBottom: 0 },
    pageSize: 6,
    hideOnSinglePage: true,
    total: _vm.total,
  }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"type":"info-circle","theme":"filled"}}),_c('span',{staticClass:"class_item_time"},[_vm._v(_vm._s(item.time))]),_c('a-tooltip',{attrs:{"placement":"left"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(item.sn)+" ")]),_c('span',{staticClass:"class_item_sn"},[_vm._v(_vm._s(item.sn))])],2),_c('a-tooltip',{attrs:{"placement":"left"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('span',{staticClass:"class_item_value"},[_vm._v(_vm._s(item.value))])],2)],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }