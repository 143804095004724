import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "FaultRank",
  props: {
    visiable: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      empty: false,
      height: 250,
      sourceData: [],
      option: null,
      myChart: null,
      resizeHandle: null,
      chartWidth: 0
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.resizeHandle = debounceTail(function () {
      return _this.resize();
    }, 299, this);
    window.addEventListener("resize", this.resizeHandle);
  },
  destroyed: function destroyed() {
    window.removeEventListener('resize', this.resizeHandle);
  },
  methods: {
    resize: function resize() {
      if (this.myChart && this.visiable) {
        this.myChart.resize();
        var newWidth = this.myChart.getWidth();

        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart: function init_chart(v) {
      if (!this.myChart) this.myChart = echarts.init(document.getElementById("dn_chart_div"));
      this.option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: function formatter(data) {
            // console.log(data);
            var ret = "\u89C4\u6A21 ".concat(data[0].name, "kg<br />");
            ret += data[0].marker + ' ' + data[0].value + ' 位客户';
            return ret;
          }
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        yAxis: {
          type: "value",
          // axisLine: {
          //   show: false
          // },
          minInterval: 1,
          splitLine: {
            lineStyle: {
              color: "#333"
            }
          },
          boundaryGap: [0, 0.01]
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#fff"
          },
          name: 'kg',
          nameGap: -15,
          nameTextStyle: {
            color: '#fff',
            verticalAlign: 'top',
            align: 'left',
            padding: [8, 0, 0, 6]
          },
          // axisLine: {
          //   show: false
          // },
          // axisTick: {
          //   show: false
          // },
          data: []
        },
        series: [{
          type: "bar",
          data: [],
          barWidth: 20,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 1,
            color: "#0c5eb2"
          }, {
            offset: 0.5,
            color: "#0d6fbb"
          }, {
            offset: 0,
            color: "#0faee1"
          }]),
          label: {
            show: true,
            position: "top"
          }
        }]
      };
      this.option.xAxis.data = v.map(function (i) {
        return i.rank;
      });
      this.option.series[0].data = v.map(function (i) {
        return i.num;
      });
      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
    update: function update(v) {
      var _this2 = this;

      if (v === undefined || v.length === 0) {
        this.empty = true;
        return;
      }

      var newdata = [];

      for (var key in v) {
        newdata.push({
          rank: key,
          num: v[key]
        });
      }

      this.sourceData = newdata;
      this.fl_empty = false;
      this.$nextTick(function () {
        _this2.init_chart(_this2.sourceData);
      });
    }
  }
};