<template>
  <div id="bmap_div" :style="`height: ${mcheight}px;overflow:hidden;`">
    <!-- mapType="BMAP_HYBRID_MAP" -->
    <baidu-map
      class="bm-view"
      :zoom="8"
      :max-zoom="16"
      :center="scenter"
      :style="`height: ${mcheight + 50}px`"
      mapType="BMAP_SATELLITE_MAP"
      @ready="mapReady"
    >
      <!-- <bm-marker
          :position="point"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        ></bm-marker> -->
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-control>
        <a-button class="map_btn" type="primary" @click="btnClick"
          >返回</a-button
        >
      </bm-control>
      <!-- <bm-overview-map
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :isOpen="true"
      ></bm-overview-map> -->
      <bm-map-type
        :map-types="['BMAP_HYBRID_MAP', 'BMAP_NORMAL_MAP']"
        anchor="BMAP_ANCHOR_TOP_LEFT"
        :offset="{ width: 85, height: 15 }"
      ></bm-map-type>
      <!-- <bm-boundary
        :name="scenter"
        :strokeWeight="1"
        strokeColor="blue"
        :fillOpacity="0.1"
        :clicking="false"
      ></bm-boundary> -->
      <bm-marker
        v-for="(point, index) in points"
        :key="index"
        :position="point"
        @click="(e) => pointClickHandler(e, point)"
      >
        <!-- <bm-label content="我爱北京天安门" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/> -->
      </bm-marker>
      <bm-info-window
        :position="infoPos"
        :show="infoWindowshow"
        @close="infoWindowClose"
        @open="infoWindowOpen"
      >
        <map-info-card
          v-if="infoWindowshow"
          :key="key"
          :devData="infoWindowdata"
          :spinning="infoWindowspinning"
          title="tit"
          @clickName="clickName"
        />
      </bm-info-window>
    </baidu-map>
  </div>
</template>

<script>
import MapInfoCard from "../../components/MapInfoCard.vue";
import { dataSource as ds } from "@/services";
export default {
  name: "MapView",
  props: {
    center: {
      type: String,
      default: "广西",
    },
    mcheight: {
      type: Number,
      default: 640,
    },
  },
  components: {
    MapInfoCard,
  },
  data() {
    return {
      loading: false,
      city: "",
      province: "",
      district: "",
      // 地图你解析方法实例
      myGeo: null,
      BMap: null,
      map: null,
      // 已选坐标，呈现mark用
      point: { lng: 116.404, lat: 39.915 },
      // 搜索关键字
      keyword: "",
      slocal: "",
      street: "",
      place: "",
      points: [],
      infoPos: { lng: 116.404, lat: 39.915 },
      infoWindowshow: false,
      infoWindowspinning: false,
      infoWindowdata: {},
      key: 0,
    };
  },
  computed: {
    scenter() {
      return this.center ? this.center : "广西";
    },
    spoint() {
      return `[ ${this.point.lng}, ${this.point.lat} ]`;
    },
  },
  methods: {
    loadData(data, region) {
      console.log("mv loaddata", data, region);
      let provinceName = data.name;
      if (region === null) return false;
      let province = region.find((p) => p.provinceName === provinceName);
      if (province) {
        console.log("mv province", province.province);
        this.getDeviceListByProvince(province.province);
        return true;
      } else {
        this.$message.info("当前选中省份无设备");
        return false;
      }
      //
    },
    setZoom(bPoints) {
      if (this.map !== null && bPoints !== null) {
        var view = this.map.getViewport(eval(bPoints));
        var mapZoom = view.zoom;
        var centerPoint = view.center;
        this.map.centerAndZoom(centerPoint, mapZoom);
      }
    },
    getDeviceListByProvince(province) {
      ds.deviceList({ pageIndex: 1, pageSize: 20, province: province }).then(
        (result) => {
          console.log("mv getDeviceList", result);
          if (result.data.meta.success) {
            let dataSource = result.data.data.list;
            console.log("dataSource", dataSource);
            // this.total = result.data.data.list.total
            if (dataSource.list.length !== 0) {
              let points = dataSource.list.map((p) => {
                return {
                  lng: p.lon,
                  lat: p.lat,
                  device: p,
                };
              });
              this.points = points;
              setTimeout(() => {
                this.setZoom(this.points);
              }, 377);
            }
          } else {
            this.$message.error(result.data.meta.msg);
          }
        }
      );
    },
    mapReady({ BMap, map }) {
      // 获取逆解析方法实例
      console.log("mapReady");
      this.myGeo = new BMap.Geocoder();
      this.BMap = BMap;
      this.map = map;
    },
    mapClick(e) {
      this.$emit("mapClick", e);
    },
    btnClick(e) {
      this.points = null;
      this.infoWindowshow = false;
      this.$emit("btnClick", e);
    },
    pointClickHandler(e, p) {
      console.log("pointClickHandler", p);
      this.infoPos = e.point;
      this.map.setCenter(e.point);
      this.key = Date.now();
      this.infoWindowshow = true;
      this.infoWindowdata = p.device;
      this.$emit("pointClick", p);
    },
    infoWindowClose() {
      console.log("infoWindowClose");
      this.infoWindowshow = false;
    },
    infoWindowOpen() {
      this.infoWindowshow = true;
      this.infoWindowspinning = true;
      setTimeout(() => {
        this.infoWindowspinning = false;
      }, 200);
    },
    clickName(e) {
      console.log("clickName", e);
      this.$router.push(`/devices/detail?sn=${e.deviceSn}&id=${e.deviceId}`);
    },
    clear() {},
  },
};
</script>

<style lang="less" scoped>
.bm-view {
  height: 100%;
  width: 100%;
  // :deep(.BMap_pop) {
  //   .BMap_center {
  //     background-color: #021548;
  //     color: #fff;
  //   }
  // }
}
#bmap_div {
  min-height: 300px;
  max-height: 920px;
  width: 100%;
  z-index: 1000;
  // background-color: #f0f2f5;
}
.map_btn {
  top: 10px;
  left: 10px;
  box-shadow: 2px 2px 3px #000a;
}
</style>
