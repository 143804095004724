import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import * as echarts from "echarts";
import chroma from "chroma-js";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "StatusChart",
  props: {
    value: {
      type: Number,
      default: 40
    },
    etc: {
      type: Number,
      default: 21
    },
    title: {
      type: String,
      default: "标题"
    },
    color: {
      type: String,
      default: "#f56163"
    }
  },
  data: function data() {
    return {
      height: 150,
      myChart: null,
      option: null,
      resizeHandle: null,
      chartWidth: 0,
      // backGroundColor: chroma("#021549").brighten(0.1).hex()
      backGroundColor: chroma(this.color).alpha(0.3).hex()
    };
  },
  watch: {
    value: function value() {
      var tmp = Math.ceil(this.value * 20 / (this.value + this.etc));

      for (var i = 0; i < 20; i++) {
        this.option.series[0].data[i].value = this.value;

        if (i < tmp) {
          this.option.series[0].data[i].itemStyle = undefined;
        } else {
          this.option.series[0].data[i].itemStyle = {
            color: this.backGroundColor
          };
        }
      } // this.myChart.clear()


      this.myChart.setOption(this.option, true);
    },
    etc: function etc() {
      var tmp = Math.ceil(this.value * 20 / (this.value + this.etc));

      for (var i = 0; i < 20; i++) {
        this.option.series[0].data[i].value = this.value;

        if (i < tmp) {
          this.option.series[0].data[i].itemStyle = undefined;
        } else {
          this.option.series[0].data[i].itemStyle = {
            color: this.backGroundColor
          };
        }
      } // this.myChart.clear()


      this.myChart.setOption(this.option, true);
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.init_chart();
    this.resizeHandle = debounceTail(function () {
      return _this.resize();
    }, 299, this);
    window.addEventListener("resize", this.resizeHandle);
  },
  destroyed: function destroyed() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    chroma: chroma,
    resize: function resize() {
      if (this.myChart) {
        this.myChart.resize();
        var newWidth = this.myChart.getWidth();

        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart: function init_chart() {
      // const colors = [this.color, this.backGroundColor, "#1fc3af"];
      var colors = [];
      var that = this;

      for (var i = 10; i < 30; i += 1) {
        colors.push(chroma(this.color).brighten(i / 10).hex());
      }

      if (!this.myChart) this.myChart = echarts.init(this.$refs.main_chart);
      this.option = {
        // title: {
        //   text: this.title,
        //   textStyle: {
        //     color: this.color,
        //   },
        //   bottom: "8%",
        //   left: 'center'
        // },
        tooltip: {
          trigger: "item",
          show: false
        },
        color: colors,
        // grid: {
        //   top: '1%',
        //   bottom: '50%',
        // },
        legend: {
          show: false
        },
        series: [{
          type: "pie",
          radius: ["58%", "80%"],
          avoidLabelOverlap: false,
          top: 0,
          bottom: "0%",
          cursor: "default",
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          emphasis: {
            scale: false
          },
          itemStyle: {
            borderRadius: 2,
            borderColor: "#011448",
            borderWidth: 3
          },
          data: [{
            value: this.value,
            name: this.title,
            tooltip: {
              show: true,
              formatter: function formatter(data) {
                // console.log(data);
                var res = "".concat(data.marker, " ").concat(data.name, " \uFF1A").concat(that.value, "\u53F0 ( ").concat(Math.round(that.value * 100 / (that.value + that.etc)), "% )"); // }台 (总${that.value + that.etc}台)`;

                return res;
              }
            },
            label: {
              show: true,
              position: "center",
              cursor: "default",
              formatter: ["{numb|{c}}", "{tai|台}"].join(""),
              padding: [6, 0, 0, 0],
              rich: {
                numb: {
                  color: this.color,
                  fontSize: 24,
                  height: 24,
                  lineHeight: 24,
                  top: 5
                },
                tai: {
                  color: this.color,
                  fontSize: 12,
                  height: 24,
                  lineHeight: 24,
                  padding: [4, 0, 0, 0] // verticalAlign: 'bottom'

                }
              }
            }
          } // { value: this.etc, name: "余量" },
          ]
        }, {
          type: "pie",
          radius: ["84%", "86%"],
          avoidLabelOverlap: false,
          tooltip: {
            show: false
          },
          top: 0,
          bottom: "0%",
          cursor: "default",
          // emptyCircleStyle: {
          //   color: "#023374",
          // },
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 0,
            itemStyle: {
              color: "#00def3"
            },
            emphasis: {
              scale: false,
              cursor: "default"
            }
          }],
          animation: false
        }, {
          type: "pie",
          radius: ["52%", "54%"],
          avoidLabelOverlap: false,
          tooltip: {
            show: false
          },
          top: 0,
          bottom: "0%",
          cursor: "default",
          // emptyCircleStyle: {
          //   color: "#023374",
          // },
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 0,
            itemStyle: {
              color: "#00def3"
            },
            emphasis: {
              scale: false,
              cursor: "default"
            }
          }],
          animation: false
        }]
      }; // this.option.xAxis.data = v.map((i) => i.rank);

      for (var _i = 1; _i < 20; _i++) {
        this.option.series[0].data.push({
          value: this.value
        });
      }

      for (var _i2 = Math.ceil(this.value * 20 / (this.value + this.etc)); _i2 < 20; _i2++) {
        this.option.series[0].data[_i2].itemStyle = {
          color: this.backGroundColor
        };
      }

      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    }
  }
};