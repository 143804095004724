import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "DeviceLocal",
  data: function data() {
    return {
      empty: true,
      height: 256,
      myChart: null,
      option: null,
      key: 0,
      currentIndex: -1,
      resizeHandle: null,
      chartWidth: 0
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.key = Date.now();
    this.resizeHandle = debounceTail(function () {
      return _this.resize();
    }, 300, this);
    window.addEventListener("resize", this.resizeHandle); // setInterval(() => {
    //   var dataLen = this.option.series[0].data.length;
    //   // 取消之前高亮的图形
    //   this.myChart.dispatchAction({
    //     type: "downplay",
    //     seriesIndex: 0,
    //     dataIndex: this.currentIndex,
    //   });
    //   this.currentIndex = (this.currentIndex + 1) % dataLen;
    //   // 高亮当前图形
    //   this.myChart.dispatchAction({
    //     type: "highlight",
    //     seriesIndex: 0,
    //     dataIndex: this.currentIndex,
    //   });
    //   // 显示 tooltip
    //   this.myChart.dispatchAction({
    //     type: "showTip",
    //     seriesIndex: 0,
    //     dataIndex: this.currentIndex,
    //   });
    // }, 1000);
  },
  destroyed: function destroyed() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    resize: function resize() {
      // console.log("dl resize");
      if (this.myChart !== null) {
        this.myChart.resize();
        var newWidth = this.myChart.getWidth();

        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        } // console.log("dl resize" ,this.myChart.getWidth());

      }
    },
    init_chart: function init_chart(v) {
      if (!this.myChart) this.myChart = echarts.init(document.getElementById("dl_chart_div"));
      this.option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          // bottom: "5%",
          top: "80%",
          bottom: "0",
          left: "center",
          textStyle: {
            color: "#fff"
          }
        },
        series: [{
          // name: "Access From",
          type: "pie",
          radius: ["25%", "70%"],
          bottom: "20%",
          avoidLabelOverlap: false,
          tooltip: {
            formatter: function formatter(data) {
              // console.log(data);
              var res = data.marker + data.name + '：' + data.value + ' 台';
              return res;
            }
          },
          startAngle: 120,
          itemStyle: {
            borderRadius: 5,
            borderColor: "#011448",
            borderWidth: 2
          },
          // roseType: 'radius',
          label: {
            show: true,
            color: "#fff",
            formatter: '{b} {d}%'
          },
          // emphasis: {
          //   label: {
          //     show: true,
          //     fontSize: "40",
          //     fontWeight: "bold",
          //   },
          // },
          // labelLine: {
          //   show: false,
          // },
          data: v
        }]
      };
      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
    update: function update(v) {
      var _this2 = this;

      // let newdata = []
      // for(let key of v){
      //   newdata.push({
      //     item: key,
      //     count: v[key]
      //   })
      // }
      if (v === undefined) {
        this.empty = true;
        return;
      }

      this.empty = false;
      var newdata = v.map(function (i) {
        return {
          name: i.provinceName,
          value: i.num
        };
      });
      newdata.sort(function (a, b) {
        return b.value - a.value;
      });
      this.$nextTick(function () {
        _this2.init_chart(newdata);
      });
    }
  }
};