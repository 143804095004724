<template>
  <div class="top_view">
    <div class="chart-content">
      <div id="chart_div">
        <a-empty v-if="empty" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "@/utils/china.js"; // 引入中国地图数据
// import "echarts/map/js/china";
// eslint-disable-next-line no-unused-vars
import { debounceTail } from "@/utils/debounce";
export default {
  name: "TopView",
  mounted() {},
  data() {
    return {
      empty: false,
      chart: null,
      rawData: null,
      GZData: [
        [
          {
            name: "广西",
          },
          {
            name: "广东",
            value: 43,
          },
        ],
        [
          {
            name: "广西",
          },
          {
            name: "北京",
            value: 10,
          },
        ],
        [
          {
            name: "广西",
          },
          {
            name: "广西",
            value: 313,
          },
        ],
      ],
    };
  },
  methods: {
    update(v) {
      console.log("tv update", v);
      if (v === undefined) {
        // this.empty = true
        // return
        this.GZData = [
          [
            {
              name: "广西",
            },
            {
              name: "广西",
              value: 1,
            },
          ],
        ];
      } else {
        this.rawData = v;
        this.GZData = v.map((i) => {
          return [
            {
              name: "广西",
            },
            {
              name: i.provinceName,
              value: i.num,
            },
          ];
        });
      }
      console.log("topView:", this.GZData, v);
      this.chart = this.drawCharts();
    },
    drawCharts() {
      let myChart = echarts.init(document.getElementById("chart_div"));
      let that = this;
      let geoCoordMap = {
        北京: [116.4551, 40.2539],
        天津: [117.4219, 39.4189],
        上海: [121.4648, 31.2891],
        重庆: [106.557165, 29.563206],
        河北: [114.508958, 38.066606],
        河南: [113.673367, 34.748062],
        云南: [102.721896, 25.047632],
        辽宁: [123.445621, 41.806698],
        湖南: [112.950888, 28.229114],
        安徽: [117.300842, 31.887672],
        山东: [117.029895, 36.677424],
        江苏: [118.814345, 32.061445],
        浙江: [120.16991, 30.272236],
        江西: [115.904962, 28.674132],
        湖北: [114.290138, 30.595623],
        甘肃: [103.851217, 36.061978],
        山西: [112.549248, 37.857014],
        陕西: [108.960062, 34.285251],
        吉林: [126.572746, 43.86785],
        福建: [119.319713, 26.072564],
        贵州: [106.427165, 26.353206],
        广东: [113.238778, 23.161621],
        青海: [101.787147, 36.621234],
        四川: [104.082256, 30.652565],
        海南: [109.910757, 19.108187],
        黑龙江: [126.655705, 45.759581],
        西藏: [91.154492, 29.665953],
        内蒙古: [111.670801, 40.818311],
        广西: [108.381781, 22.815042],
        宁夏: [106.234805, 38.487468],
        新疆: [87.616327, 43.800508],
        香港: [114.168545, 22.36641],
        澳门: [113.549978, 22.1943],
        台湾: [121.098613, 23.778734],
      };


      var convertData = function(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = geoCoordMap[dataItem[1].name];
          var toCoord = geoCoordMap[dataItem[0].name];
          if (fromCoord && toCoord) {
            // res.push([
            //   {
            //     coord: fromCoord,
            //   },
            //   {
            //     coord: toCoord,
            //   },
            // ]);
            res.push({
              fromName: dataItem[0].name,
              toName: dataItem[1].name,
              numValue: dataItem[1].value,
              coords: [fromCoord, toCoord],
            });
          }
        }
        return res;
      };

      var color = ["#02bef1", "#ffa022", "#46bee9"];
      var planePath = "path://M1705.06,1318.313v-300";
      let LineColor = ["#ff3333", "orange", "lime", "aqua"];
      var series = [];
      [["广西", this.GZData]].forEach(function(item, i) {
        console.log("fsdf", item);
        series.push(
          // 设置飞行线
          {
            name: item[0],
            type: "lines",
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              trailLength: 0.7,
              color: "#fefdf1",
              symbolSize: 3,
            },
            lineStyle: {
              normal: {
                type: [5, 3],
                dashOffset: 5,
                color: color[i],
                width: 1,
                curveness: 0.2,
              },
            },
            data: convertData(item[1]),
          },
          // 设置轨迹线
          {
            name: item[0],
            type: "lines",
            zlevel: 2,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              color: "#fefdf1",
              symbol: planePath,
              symbolSize: 15,
            },
            lineStyle: {
              normal: {
                // color: color[i],
                color: function(params) {
                  // console.log(params);
                  let num = params.data.numValue;
                  if (num > 75) {
                    return LineColor[0];
                  } else if (num > 50) {
                    return LineColor[1];
                  } else if (num > 25) {
                    return LineColor[2];
                  } else {
                    return LineColor[3];
                  }
                },
                type: [5, 3],
                dashOffset: 5,
                width: 1,
                opacity: 0.7,
                curveness: 0.2,
              },
            },
            data: convertData(item[1]),
          },
          // 设置点
          {
            name: item[0],
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 3,
            rippleEffect: {
              brushType: "stroke",
            },
            label: {
              normal: {
                show: false,
                position: "right",
                // formatter: "{b}",
                formatter: function(params) {
                  // var res = params.value[2];
                  let res = params.name + "\n" + params.value[2] + "台";
                  return res;
                },
              },
            },
            // symbolSize: function(val) {
            //   return val[2] / 8;
            // },
            symbolSize: 10,
            itemStyle: {
              normal: {
                color: function(params) {
                  // console.log('itemStyle color', params);
                  let num = params.data.value[2];
                  if (num > 10) {
                    return '#f0f48c';
                  } else {
                    return '#0bd7e6';
                  }
                },
              },
            },
            data: item[1].map(function(dataItem) {
              return {
                name: dataItem[1].name,
                value: geoCoordMap[dataItem[1].name].concat([
                  dataItem[1].value,
                ]),
              };
            }),
          }
        );
      });
      // eslint-disable-next-line no-unused-vars
      let option = {
        backgroundColor: "#fff0",
        title: {
          text: "机器分布图",
          left: "center",
          top: 40,
          textStyle: {
            color: "#0371a7",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            // console.log(params)
            if (params.componentSubType === "map") {
              if (params.value === undefined || isNaN(params.value))
                return null;
              else {
                let res = params.name + "<br/>" + params.data.value[2] + "台";
                return res;
              }
            } else if (params.componentSubType === "effectScatter") {
              if (params.value === undefined) return null;
              else {
                let res =
                  "地区：" +
                  params.name +
                  "<br/>安装：" +
                  params.data.value[2] +
                  "台";
                return res;
              }
            }
          },
        },
        geo: {
          map: "china",
          emphasis: {
            label: {
              show: false,
            },
            // focus: "self",
          },
          z: 1,
          zoom: 1.2,
          blur: {
            itemStyle: {
              opacity: 0,
            },
          },
          // tooltip: {
          //   formatter: function(params) {
          //     // console.log(params, that.rawData)
          //     let name = params.name;
          //     if (that.rawData !== null) {
          //       let pd = that.rawData.find((i) => {
          //         return i.provinceName === name;
          //       });
          //       if (pd) {
          //         let res =
          //           "地区：" + params.name + "<br/>安装：" + pd.num + "台";
          //         return res;
          //       }
          //     }
          //     return name;
          //   },
          // },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "#0371a7",
              // borderColor: "#00c6eb",

              borderColor: "#00ecff",
              borderWidth: 3, //描边线宽。为 0 时无描边
              opacity: 0.8,
              shadowColor: "#01357d", //阴影颜色
              shadowOffsetX: 5, //阴影水平方向上的偏移距离
              shadowOffsetY: 5, //阴影垂直方向上的偏移距离
            },
            emphasis: {
              areaColor: "#0ca0cb",
            },
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                areaColor: "red",
                color: "red",
                opacity: 0,
              },
            },
          ],
        },
        series: series,
      };
      series.push(
        //数据
        {
          type: "map", //图表的类型
          map: "china",
          zoom: 1.2,
          z: 2,
          emphasis: {
            focus: "self",
            label: {
              show: false,
            },
            itemStyle: {
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#0344bc", // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#061d76", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              shadowColor: "#112554", //阴影颜色
              borderColor: "#00ecff",
              opacity: 1,
              borderWidth: 1, //描边线宽。为 0 时无描边
              shadowOffsetX: 5, //阴影水平方向上的偏移距离
              shadowOffsetY: 5, //阴影垂直方向上的偏移距离
            },
          },
          blur: {
            itemStyle: {
              color: {
                type: "radial",
                x: 500,
                y: 400,
                r: 400,
                colorStops: [
                  {
                    offset: 0,
                    color: "#1961ba", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#0538ab", // 100% 处的颜色
                  },
                ],
                global: true, // 缺省为 false
              },
              opacity: 1,
            },
          },
          tooltip: {
            formatter: function(params) {
              // console.log(params, that.rawData)
              let name = params.name;
              if (that.rawData !== null) {
                let pd = that.rawData.find((i) => {
                  return i.provinceName === name;
                });
                if (pd) {
                  let res =
                    "地区：" + params.name + "<br/>安装：" + pd.num + "台";
                  return res;
                }
              }
              return name;
            },
          },
          select: {
            label: {
              show: false,
            },
            itemStyle: {
              // color: "#1658b5",
              areaColor: "auto",
              // focus: "self",
            },
          },
          roam: false,
          itemStyle: {
            areaColor: {
              type: "radial",
              x: 500,
              y: 400,
              r: 400,
              colorStops: [
                {
                  offset: 0,
                  color: "#1961ba", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#0538ab", // 100% 处的颜色
                },
              ],
              global: true, // 缺省为 false
            },
            borderColor: "#18a0f7",
          },
          data: [],
        }
      );
      // eslint-disable-next-line no-unused-vars
      let option3 = {
        title: {
          //标题
          text: "地图",
          subtext: "11111",
          x: "center", //居中
          textStyle: {
            //标题 样式
            color: "#9c0505",
            fontSize: "32",
          },
          subtextStyle: {
            color: "#AFAFAF",
            fontSize: "16",
          },
        },
        tooltip: {
          //提示信息
          trigger: "item", //类型
          //地图 : {a}（系列名称），{b}（区域名称），{c}（合并数值）, {d}（无）
          formatter: "地区：{b}<br/>数量：{c}",
        },
        series: [
          //数据
          {
            type: "map", //图表的类型
            map: "china",
            label: {
              //图形上的文本标签，可用于说明图形的一些数据信息
              show: true,
              color: "red",
              fontSize: 10,
            },
            zoom: 1, //当前视角的缩放比例。
            itemStyle: {
              //地图区域的多边形 图形样式。
              borderColor: "blue",
            },
            emphasis: {
              //高亮状态下的设置
              label: {
                //图形上的文本标签，可用于说明图形的一些数据信息
                color: "#fff",
                fontSize: 12,
              },
              itemStyle: {
                //地图区域的多边形 图形样式。
                areaColor: "green",
              },
            },
            data: [
              {
                name: "河南",
                value: 675,
              },
              {
                name: "云南",
                value: 117,
              },
              {
                name: "广西",
                value: 139,
              },
            ],
          },
        ],
        visualMap: {
          //视觉地图
          type: "piecewise", //分段型
          show: true,
          pieces: [
            { min: 10000 }, // 不指定 max，表示 max 为无限大（Infinity）。
            { min: 1000, max: 9999 },
            { min: 100, max: 999 },
            { min: 10, max: 99 },
            { min: 1, max: 9 },
            { value: 0 }, // 不指定 min，表示 min 为无限大（-Infinity）。
          ],
          inRange: {
            //范围
            color: ["#fff", "#ffaa85", "#660208"],
          },
          itemWidth: 10,
          itemHeight: 10,
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      myChart.on("click", (params) => {
        //点击事件
        // if (params.componentType === "series") {
        //   var provinceName = params.name;
        // }
        // console.log(params);
        that.$emit("click", params);
      });
      // window.addEventListener("resize", debounceTail(
      //     ()=>myChart.resize(), 2000, this
      //   )
      // );
      return myChart;
    },
  },
};
</script>
<style lang="less" scoped>
.top_view {
  .chart-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #chart_div {
    min-height: 300px;
    max-height: 640px;
    min-width: 100px;
    max-width: 920px;
    height: 640px;
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0); */
    display: flex;
    align-items: center;
    justify-content: center;
    // color: #0740ad;
  }
}
</style>
