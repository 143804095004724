<template>
  <a-list size="small" class='class_faultlist' :data-source="data" 
    :pagination="{
      position: 'bottom',
      size: 'small',
      style: { marginBottom: 0 },
      pageSize: 6,
      hideOnSinglePage: true,
      total: total,
    }"
  >
    <a-list-item slot="renderItem" slot-scope="item">
      <a-icon type="info-circle" theme="filled" style="color:red;" />
      <span class="class_item_time">{{ item.time }}</span>
      <a-tooltip placement="left">
        <template slot="title">
          {{ item.sn }}
        </template>
        <span class="class_item_sn" >{{ item.sn }}</span>
      </a-tooltip>
      <a-tooltip placement="left">
        <template slot="title">
          {{ item.value }}
        </template>
        <span class="class_item_value" >{{ item.value }}</span>
      </a-tooltip>
    </a-list-item>
    <!-- <div slot="header">
      Header
    </div>
    <div slot="footer">
      Footer
    </div> -->
  </a-list>
</template>

<script>
const data = [];
export default {
  name: 'FaultList',
  data() {
    return {
      data,
      total: 0,
    };
  },
  methods: {
    update(v) {
      console.log('fl update', v)
      this.total = v.length;
      this.data = v.map(i=>{
        return {
          time: i.createtime,
          sn: i.deviceSn,
          value: i.errContent,
        }
      })
    },
    onChange(e) {
      console.log('fl onChange', e)
    }
  }
};
</script>
<style lang="less" scoped>
.class_faultlist {
  overflow: hidden;
  .class_item_time {
    margin-left: 3px;
    color: #5381b2;
    width: 130px;
    white-space: nowrap;
  }
  .class_item_sn {
    margin-left: auto;
    color: #fff;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .class_item_value {
    margin-left: 10px;
    color: #fff;
    width: 128px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  :deep(.ant-list-item) {
    border-bottom: 1px solid #fff2;
  }
  :deep(.ant-list-item) {
    &:last-child {
      border-bottom: none;
    }
  }
  :deep(.ant-empty-description) {
    color: #00f8fe;
  }
  :deep(.ant-list-pagination) {
    margin-top: 5px
  }
}
</style>
