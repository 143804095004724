var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "chart_device_status"
  }, [_vm.empty ? _c('a-empty') : _c('div', {
    staticClass: "charts"
  }, [_c('status-chart', {
    ref: "chart1",
    staticClass: "chart",
    style: "height: ".concat(_vm.height, "px"),
    attrs: {
      "title": "开机",
      "color": "#43d57e",
      "value": _vm.data[0].value,
      "etc": _vm.data[0].total - _vm.data[0].value
    }
  }), _c('status-chart', {
    ref: "chart2",
    staticClass: "chart",
    style: "height: ".concat(_vm.height, "px"),
    attrs: {
      "title": "运行",
      "color": "#f9c762",
      "value": _vm.data[1].value,
      "etc": _vm.data[1].total - _vm.data[1].value
    }
  }), _c('status-chart', {
    ref: "chart3",
    staticClass: "chart",
    style: "height: ".concat(_vm.height, "px"),
    attrs: {
      "title": "故障",
      "value": _vm.data[2].value,
      "etc": _vm.data[2].total - _vm.data[2].value
    }
  })], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };