<template>
  <div style="position: relative;">
    <div ref="main_chart" class="chart" :style="`height: ${height}px`" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "SalesData",
  props: {
    inp: {
      type: Number,
      default: 40,
    },
    outp: {
      type: Number,
      default: 21,
    },
    dec: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height: 198,
      myChart: null,
      option: null,
      resizeHandle: null,
      chartWidth: 0,
    };
  },
  watch: {
    inp() {
      let gt = Math.round(this.inp * 1.47 * 100) / 100;
      this.option.series[0].data = [
        { value: this.inp, name: "处理量" },
        { value: this.outp, name: "产出物" },
        { value: gt, name: "减碳量" },
      ];
      this.myChart.setOption(this.option);
    },
    outp() {
      let gt = Math.round(this.inp * 1.47 * 100) / 100;
      this.option.series[0].data = [
        { value: this.inp, name: "处理量" },
        { value: this.outp, name: "产出物" },
        { value: gt, name: "减碳量" },
      ];
      this.myChart.setOption(this.option);
    },
  },
  mounted() {
    this.init_chart();
    this.resizeHandle = debounceTail(() => this.resize(), 299, this);
    window.addEventListener(
      "resize",
      this.resizeHandle
    );
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandle)
  },
  methods: {
    resize() {
      if (this.myChart) {
        this.myChart.resize();
        let newWidth = this.myChart.getWidth();
        if(this.chartWidth!== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart() {
      const colors = ["#19d1ff", "#0078fc", "#1fc3af"];
      if (!this.myChart) this.myChart = echarts.init(this.$refs.main_chart);
      let gt = Math.round(this.inp * 1.47 * 100) / 100;
      this.option = {
        tooltip: {
          trigger: "item",
        },
        color: colors,
        // grid: {
        //   top: '1%',
        //   bottom: '50%',
        // },
        legend: {
          bottom: 0,
          left: "center",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          // formatter: function(data) {
          //   console.log(data);
          //   let res = data.marker + data.name + ':' + data.value;
          //   return res
          // },
          itemGap: 5,
          padding: [5, 5],
          data: [
            {
              icon: "circle",
              name: "处理量",
            },
            {
              icon: "circle",
              name: "产出物",
            },
            {
              icon: "circle",
              name: "减碳量",
            },
          ],
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "68%"],
            center: ['50%', '46%'],
            avoidLabelOverlap: false,
            top: 0,
            bottom: "10%",
            // tooltip: {
            //   formatter: function(data) {
            //     console.log(data);
            //     let res = data.marker + data.name + ':' + data.value;
            //     return res
            //   },
            // },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderRadius: 2,
              borderColor: "#011448",
              borderWidth: 2,
            },
            data: [
              { value: this.inp, name: "处理量" },
              { value: this.outp, name: "产出物" },
              {
                value: gt,
                name: "减碳量",
              },
            ],
          },
          {
            type: "pie",
            radius: ["72%", "74%"],
            center: ['50%', '46%'],
            avoidLabelOverlap: false,
            tooltip: {
              show: false,
            },
            top: 0,
            bottom: "10%",
            cursor: "auto",
            // emptyCircleStyle: {
            //   color: "#023374",
            // },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#023374",
                },
                emphasis: {
                  scale: false,
                },
              },
            ],
            animation: false,
          },
          {
            type: "pie",
            radius: ["36%", "34%"],
            center: ['50%', '46%'],
            avoidLabelOverlap: false,
            tooltip: {
              show: false,
            },
            top: 0,
            bottom: "10%",
            cursor: "auto",
            emptyCircleStyle: {
              color: "#023374",
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [],
            animation: false,
          },
        ],
      };
      // this.option.xAxis.data = v.map((i) => i.rank);
      // this.option.series[0].data = v.map((i) => i.num);
      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
  },
};
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>
