<template>
  <div class="analysis">
    <a-row style="margin-top: 0" :gutter="[24, 24]">
      <a-col :xs="{ span: 12, push: 6 }">
        <div class="class_top_view">
          <transition name="fade">
            <div
              v-show="showmap"
              :loading="loading.default"
              class="class_top_view_item"
            >
              <map-view
                ref="mapview"
                :center="selectregion"
                @btnClick="bmapClick"
                @mapClick="bmapClick"
              />
            </div>
          </transition>
          <transition name="fade">
            <top-view
              v-show="!showmap"
              ref="topview"
              class="class_top_view_item"
              :loading="loading.default"
              @click="mapClick"
            />
          </transition>
        </div>
        <a-card
          :bodyStyle="{ tableCardStyle }"
          class="class_table_card"
          :bordered="false"
        >
          <hot-search ref="reflist" />
        </a-card>
        <!-- <a-card :loading="loading.default" :bordered="false" style="margin-top: 24px;" title="$t('proportion')">
          <a-radio-group slot="extra" style="margin: -12px 0">
            <a-radio-button value="a">{{$t('all')}}</a-radio-button>
            <a-radio-button value="b">{{$t('online')}}</a-radio-button>
            <a-radio-button value="c">{{$t('stores')}}</a-radio-button>
          </a-radio-group>
        </a-card> -->
      </a-col>
      <a-col :xs="{ span: 6, pull: 12 }">
        <chart-card
          :loading="loading.deviceoverview"
          title="设备处理量"
          class="standard_card"
        >
          <span slot="action">
            <a-tooltip
              title="刷新"
              style="margin-right:10px;"
              @click="deviceTotalUpdate"
            >
              <a-icon type="reload" />
            </a-tooltip>
            <a-tooltip title="设备处理量" slot="action" @click="test">
              <a-icon type="info-circle-o" />
            </a-tooltip>
          </span>
          <div>
            <a-row :gutter="[24, 24]">
              <a-col :xs="14">
                <div class="base_info">
                  <div class="base_info_bar">
                    <span class="title">处理量</span
                    ><span class="value">{{ input }} kg</span>
                  </div>
                  <mini-progress
                    percent="50"
                    color="#19d1ff"
                    bkcolor="#1f3055"
                    height="10px"
                  />
                  <div class="base_info_bar">
                    <span class="title">产出物</span
                    ><span class="value">{{ output }} kg</span>
                  </div>
                  <mini-progress
                    :percent="(output / input) * 50"
                    color="#007bff"
                    bkcolor="#1f3055"
                    height="10px"
                  />
                  <div class="base_info_bar">
                    <span class="title">减碳量</span
                    ><span class="value">{{
                      Math.floor(input * 1.47 * 10) / 10
                    }} kg</span>
                  </div>
                  <mini-progress
                    :percent="50 * 1.47"
                    color="#1fc3af"
                    bkcolor="#1f3055"
                    height="10px"
                  />
                </div>
              </a-col>
              <a-col :xs="10">
                <sales-data ref="refsd" :inp="input" :outp="output" />
              </a-col>
            </a-row>
          </div>
        </chart-card>
        <chart-card
          :loading="loading.devicenum"
          class="standard_card_b"
          title="客户日处理规模（kg）"
        >
          <span slot="action">
            <a-tooltip
              title="刷新"
              style="margin-right:10px;"
              @click="deviceNumUpdate"
            >
              <a-icon type="reload" />
            </a-tooltip>
            <a-tooltip title="规模数量">
              <a-icon type="info-circle-o" />
            </a-tooltip>
          </span>
          <div>
            <!-- <mini-area /> -->
            <device-num ref="refdn" />
          </div>
        </chart-card>
        <chart-card
          :loading="loading.devicelocal"
          class="standard_card_b"
          title="设备分布"
        >
          <span slot="action">
            <a-tooltip
              title="刷新"
              style="margin-right:10px;"
              @click="deviceLoaclUpdate"
            >
              <a-icon type="reload" />
            </a-tooltip>
            <a-tooltip title="设备分布">
              <a-icon type="info-circle-o" />
            </a-tooltip>
          </span>
          <div>
            <device-local ref="refdl" />
          </div>
        </chart-card>
      </a-col>
      <a-col :xs="6">
        <chart-card
          :loading="loading.monitor"
          class="standard_card_m"
        >
          <monitor-view />
        </chart-card>
        <chart-card
          :loading="loading.totleDeviceStatus"
          class="standard_card"
          title="设备运行情况"
        >
          <span slot="action">
            <a-tooltip
              title="刷新"
              style="margin-right:10px;"
              @click="totleDeviceStatusUpdate"
            >
              <a-icon type="reload" />
            </a-tooltip>
            <a-tooltip title="设备运行情况">
              <a-icon type="info-circle-o" />
            </a-tooltip>
          </span>
          <div>
            <device-status ref="reftds" />
          </div>
        </chart-card>
        <chart-card
          :loading="loading.faultlist"
          class="standard_card_b"
          title="故障记录"
        >
          <span slot="action">
            <a-tooltip
              title="刷新"
              style="margin-right:10px;"
              @click="faultlistUpdate"
            >
              <a-icon type="reload" />
            </a-tooltip>
            <a-tooltip title="故障记录">
              <a-icon type="info-circle-o" />
            </a-tooltip>
          </span>
          <div>
            <fault-list ref="reffl" />
          </div>
        </chart-card>
        <chart-card
          v-if="false"
          :loading="loading.faultrank"
          class="standard_card_b"
          title="故障排行"
        >
          <span slot="action">
            <a-tooltip
              title="刷新"
              style="margin-right:10px;"
              @click="faultRankUpdate"
            >
              <a-icon type="reload" />
            </a-tooltip>
            <a-tooltip title="故障排行">
              <a-icon type="info-circle-o" />
            </a-tooltip>
          </span>
          <div>
            <fault-rank ref="reffr" />
          </div>
        </chart-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ChartCard from "./chart/ChartCard";
import MiniProgress from "./chart/MiniProgress";
import HotSearch from "./HotSearch";

import TopView from "./TopView";
import MapView from "./MapView";
import MonitorView from "./MonitorView";
import SalesData from "./chart/SalesData";
import DeviceStatus from "./chart/DeviceStatus";
import FaultList from "./FaultList.vue";
import FaultRank from "./chart/Faultrank";
import DeviceNum from "./chart/DeviceNum";
import DeviceLocal from "./chart/DeviceLocal.vue";
// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
// import { format } from "date-fns";
import { mapState } from "vuex";

// const beginDay = new Date().getTime();
const rankList = [];

for (let i = 0; i < 8; i++) {
  rankList.push({
    name: "桃源村" + i + "号店",
    total: 1234.56 - i * 100,
  });
}

export default {
  name: "Analysis",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    HotSearch,
    MiniProgress,
    ChartCard,
    TopView,
    MapView,
    SalesData,
    DeviceStatus,
    FaultList,
    FaultRank,
    DeviceNum,
    DeviceLocal,
    MonitorView,
  },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      rankList,
      showmap: false,
      selectregion: "",
      tableCardStyle: {
        padding: 0,
      },
      loading: {
        deviceoverview: false,
        devicenum: false,
        devicelocal: false,
        totleDeviceStatus: false,
        faultlist: false,
        faultrank: false,
        default: true,
        monitor: false,
      },
      stime: "",
      etime: "",
      region: null,
      input: 100,
      output: 80,
      TickTimer10: null,
      TickTimer60: null,
      visiable: false,
      timeChanged: false,
    };
  },
  computed: {
    ...mapState("date", ["timeRange"]),
  },
  watch: {
    timeRange: function(li) {
      console.log("timeRange改变", li);
      this.stime = li[0];
      this.etime = li[1];
      if(this.visiable) {
        this.updateAllbyDate();
      } else {
        this.timeChanged = true;
      }
    },
  },
  created() {
    // setTimeout(() => (this.loading.default = !this.loading.default), 1000);
    // this.totleDeviceStatusUpdate()
    // this.deviceTotalUpdate()
    // this.deviceRegionUpdate()
    // this.deviceNumUpdate()
    // this.faultlistUpdate()
    // this.deviceLoaclUpdate()
    // this.faultRankUpdate()
    this.stime = this.timeRange[0];
    this.etime = this.timeRange[1];
    console.log("stime", this.stime, this.etime);
  },
  mounted() {
    this.updateAll();
  },
  beforeDestroy() {
    if (this.TickTimer10) {
      clearInterval(this.TickTimer10);
      this.TickTimer10 = null;
    }
    if (this.TickTimer60) {
      clearInterval(this.TickTimer60);
      this.TickTimer60 = null;
    }
  },
  activated() {
    console.log("device List activated");
    this.visiable = true;
    this.handelResize();
    if (this.TickTimer10 === null) {
      this.TickTimer10 = setInterval(() => {
        this.deviceListUpdate();
      }, 9717);
    }
    if (this.TickTimer60 === null) {
      this.TickTimer60 = setInterval(() => {
        // this.deviceListUpdate();
      this.faultlistUpdate(false);
      this.totleDeviceStatusUpdate(false);
      }, 94713);
    }
    if(this.timeChanged) {
      this.updateAllbyDate();
    }
  },
  deactivated() {
    console.log("device List deactivated");
    this.visiable = false;
    if (this.TickTimer10) {
      clearInterval(this.TickTimer10);
      this.TickTimer10 = null;
    }
    if (this.TickTimer60) {
      clearInterval(this.TickTimer60);
      this.TickTimer60 = null;
    }
  },
  methods: {
    handelResize() {
      console.log('A handelResize');
      this.$refs.refdl.resize();
      this.$refs?.reffr?.resize();
      this.$refs.refdn.resize();
      this.$refs.refsd.resize();
      this.$refs.reftds.resize();
    },
    updateAll() {
      this.totleDeviceStatusUpdate();
      this.deviceTotalUpdate();
      this.deviceRegionUpdate();
      this.deviceNumUpdate();
      this.faultlistUpdate();
      this.deviceLoaclUpdate();
      // this.faultRankUpdate();
      this.deviceListUpdate();
      if (this.TickTimer10 === null) {
        this.TickTimer10 = setInterval(() => {
          this.deviceListUpdate();
        }, 9700);
      }
    },
    updateAllbyDate() {
      this.deviceTotalUpdate();
      this.faultlistUpdate();
      // this.faultRankUpdate();
    },
    mapClick(e) {
      console.log("topview click:", e);
      if (e.name === "") {
        //
      } else {
        this.selectregion = e.name;
        if (this.$refs.mapview.loadData(e, this.region)) {
          this.showmap = true;
        }
      }
    },
    test() {
      this.showmap = !this.showmap;
    },
    deviceTotalUpdate() {
      console.log("deviceTotalUpdate");
      this.loading.deviceoverview = true;
      // setTimeout(() => (this.loading.deviceoverview = !this.loading.deviceoverview), 1000);
      // ds.deviceInOutData().then(res=>{
      //   console.log('deviceInOutData', res)
      //   // this.$refs.refdn.update(res.data.data.data)
      // }).catch(err=>{
      //   console.log('deviceInOutDataErr', err)
      // }).finally(()=>{
      //   this.loading.deviceoverview = false
      // })
      ds.deviceDayData({
        stime: this.stime,
        etime: this.etime,
      })
        .then((res) => {
          console.log("deviceDayData", res);
          if (res.data.meta.code !== 6666) throw res.data.meta.msg;
          const sinput = Number(((res.data.data.data?.in || 0) + (res.data.data.data?.pltWst || 0)).toFixed(2));    // 加上园林垃圾
          this.input = sinput;
          // const soutput = Number(((res.data.data.data?.out || 0) + (res.data.data.data?.pltWst || 0)).toFixed(2));    // 加上园林垃圾
          this.output = res.data.data.data?.out;
        })
        .catch((err) => {
          console.log("deviceDayDataErr", err);
          this.$message.error(`获取数据出错：${err.message}`);
        })
        .finally(() => {
          this.loading.deviceoverview = false;
        });
    },
    deviceRegionUpdate() {
      console.log("deviceRegionUpdate");
      // this.loading.deviceoverview = true
      // setTimeout(() => (this.loading.deviceoverview = !this.loading.deviceoverview), 1000);
      ds.deviceRegion()
        .then((res) => {
          console.log("deviceRegion", res);
          // this.$refs.refdn.update(res.data.data.data)
        })
        .catch((err) => {
          console.log("deviceRegionErr", err);
          this.$message.error(`获取数据出错：${err.message}`);
        })
        .finally(() => {
          this.loading.devicenum = false;
        });
    },
    deviceNumUpdate() {
      console.log("deviceNumUpdate");
      this.loading.devicenum = true;
      ds.produceScale()
        .then((res) => {
          console.log("deviceNumUpdate", res);
          this.$refs.refdn.update(res.data.data.data);
        })
        .catch((err) => {
          console.log("deviceNumUpdateErr", err);
          this.$message.error(`获取数据出错：${err.message}`);
          this.$refs.refdn.update();
        })
        .finally(() => {
          this.loading.devicenum = false;
        });
    },
    deviceLoaclUpdate() {
      console.log("deviceLoaclUpdate");
      this.loading.devicelocal = true;
      ds.deviceRegion()
        .then((res) => {
          console.log("deviceLoaclUpdate", res);
          this.$refs.topview.update(res.data.data.data);
          this.$refs.refdl.update(res.data.data.data);
          this.region = res.data.data.data;
        })
        .catch((err) => {
          this.$refs.topview.update();
          this.$refs.refdl.update();
          this.$message.error(`获取数据出错：${err.message}`);
          console.log("deviceLoaclUpdate", err);
        })
        .finally(() => {
          this.loading.devicelocal = false;
        });
    },
    totleDeviceStatusUpdate(loading = true) {
      console.log("totleDeviceStatusUpdate");
      this.loading.totleDeviceStatus = loading;
      // setTimeout(() => (this.loading.totleDeviceStatus = !this.loading.totleDeviceStatus), 1000);
      ds.deviceStatusData()
        .then((res) => {
          console.log("deviceStatusData", res);
          if (res.data.meta.code !== 6666) throw res.data.meta.msg;
          this.$refs.reftds.update(res.data.data.data);
        })
        .catch((err) => {
          console.log("deviceStatusDataErr", err);
          this.$message.error(`获取数据出错：${err.message}`);
          this.$refs.reftds.update();
        })
        .finally(() => {
          this.loading.totleDeviceStatus = false;
        });
    },
    faultlistUpdate(loading = true) {
      console.log("faultlistUpdate");
      this.loading.faultlist = loading;
      ds.deviceErrorList({
        all: 0,
        status: 1,
        pageIndex: 1,
        pageSize: 50,
        stime: this.stime,
        etime: this.etime,
      })
        .then((res) => {
          console.log("deviceErrorList", res);
          if (res.data.meta.code !== 6666) throw res.data.meta.msg;
          // this.$refs.reffl.update(res.data.data.list.list.slice(0, 6));
          this.$refs.reffl.update(res.data.data.list.list);
        })
        .catch((err) => {
          console.log("deviceErrorListErr", err);
          this.$message.error(`获取数据出错：${err.message}`);
        })
        .finally(() => {
          this.loading.faultlist = false;
        });
    },
    faultRankUpdate() {
      console.log("faultRankUpdate");
      this.loading.faultrank = true;
      ds.deviceErrRank({ stime: this.stime, etime: this.etime })
        .then((res) => {
          console.log("faultRankUpdate", res);
          if (res.data.meta.code !== 6666) throw res.data.meta.msg;
          this.$refs?.reffr?.update(res.data.data.data.slice(0, 6));
        })
        .catch((err) => {
          console.log("faultRankUpdateErr", err);
          this.$message.error(`获取数据出错：${err.message}`);
        })
        .finally(() => {
          this.loading.faultrank = false;
        });
    },
    async deviceListUpdate() {
      console.log("deviceListUpdate");
      try {
        await this.$refs.reflist.update();
      } catch (err) {
        // console.error(err)
        if (err.code === 1006) {
          this.$message.error("获取设备列表出错：登录已过期，请重新登录");
          this.$router.push("/login");
        } else {
          this.$message.error("获取设备列表出错：" + err.msg);
        }
      }
      console.log("deviceListUpdate f");
    },
    bmapClick(e) {
      console.log(e);
      this.showmap = false;
      this.$nextTick(() => {
        this.$refs.topview.chart.resize();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.extra-wrap {
  .extra-item {
    display: inline-block;
    margin-right: 24px;
    a:not(:first-child) {
      margin-left: 24px;
    }
  }
}
@media screen and (max-width: 992px) {
  .extra-wrap .extra-item {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .extra-wrap {
    display: none;
  }
}
.class_top_view {
  position: relative;
  height: 674px;
  // background-color: #f0f2f5;
  &_item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
  }
}
.standard_card {
  height: 250px;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}
.standard_card_b {
  height: 315px;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}
.standard_card_m {
  height: 315px;
  margin-top: 20px;
  padding: 0px;
  :deep(.ant-card-body) {
    padding: 1px !important;
  }

  &:first-child {
    margin-top: 0;
  }
}
.base_info {
  margin-top: 30px;
  .base_info_bar {
    display: flex;
    width: 100%;
    height: 15px;
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    .title {
      // color: #FFFFFF;
      color: #ffffff;
    }
    .value {
      margin-left: auto;
      color: #ffffff;
    }
  }
}
.fade-enter-active {
  transition: all 0.3s ease 0.3s;
}
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to
/* .fade-leave-active for below version 2.1.8 */ {
  // transform: scale(0);
  filter: opacity(0);
  // opacity: 0;
}
</style>

<style lang="less">
.class_table_card {
  // background-color: #f0f2f5;
  background-color: #ffffff00;
  .ant-card-body {
    padding: 1px 1px 1px;
  }
}
</style>
