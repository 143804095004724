import _toConsumableArray from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import StatusChart from "./StatusChart.vue";
var _data = [{
  type: "开机",
  value: 0,
  total: 9
}, {
  type: "运行",
  value: 0,
  total: 9
}, {
  type: "故障",
  value: 0,
  total: 9
}];
export default {
  data: function data() {
    return {
      data: _data,
      empty: true,
      height: 180
    };
  },
  components: {
    StatusChart: StatusChart
  },
  methods: {
    resize: function resize() {
      var _this$$refs$chart, _this$$refs$chart2, _this$$refs$chart3;

      (_this$$refs$chart = this.$refs.chart1) === null || _this$$refs$chart === void 0 ? void 0 : _this$$refs$chart.resize();
      (_this$$refs$chart2 = this.$refs.chart2) === null || _this$$refs$chart2 === void 0 ? void 0 : _this$$refs$chart2.resize();
      (_this$$refs$chart3 = this.$refs.chart3) === null || _this$$refs$chart3 === void 0 ? void 0 : _this$$refs$chart3.resize();
    },
    update: function update(v) {
      if (v === undefined) {
        this.empty = true;
        return;
      }

      this.empty = false;
      var stop = v.stop,
          runing = v.runing,
          error = v.error,
          close = v.close; // let { stop, runing, error, close } = {
      //   stop: Math.round(Math.random()*10),
      //   runing: Math.round(Math.random()*10),
      //   error: Math.round(Math.random()*10),
      //   close: Math.round(Math.random()*10),
      // };

      var total = stop + runing + error + close;

      var newdata = _toConsumableArray(this.data);

      newdata[0].total = total;
      newdata[0].value = runing; //ok

      newdata[1].total = total;
      newdata[1].value = stop; //stop

      newdata[2].total = total;
      newdata[2].value = error; //error

      this.data = newdata;
    }
  }
};