<template>
  <div class="top-device">
    <a-table
      class="top-device-table"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{
        position: 'bottom',
        style: { marginBottom: 0 },
        pageSize: 4,
        total: total,
      }"
      :padding="[10, 0, 'auto', 0]"
      size="small"
      rowKey="deviceId"
      @change="onChange"
    >
      <div slot="itemsn" slot-scope="record" style="height:21px;">
        <a
          class="top-device-table_title"
          href="javascript:;"
          :title="record.deviceSn"
          @click="device_click(record)"
          >{{ record.deviceSn }}</a
        >
      </div>
      <span slot="rang" slot-scope="text"
        >{{ text }} %<a-icon type="caret-up" />
      </span>
      <div slot="status" slot-scope="record">
        <span v-if="record.deviceInfos === null" style="color:brown">
          离线
        </span>
        <span v-else-if="record.deviceInfos.devSta === '0'" style="color:green">
          运行
        </span>
        <span
          v-else-if="record.deviceInfos.devSta === '1'"
          style="color:#0095f4"
        >
          开机
        </span>
        <span v-else style="color:red;">
          故障
        </span>
      </div>
      <div slot="action" slot-scope="record">
        <!-- <a-popconfirm
          v-if="record.permissionStatus === 1"
          title="停止运行?"
          @confirm="() => editForm(record)"
        >
          <a>
            授权
          </a>
        </a-popconfirm>
        <a-popconfirm
          v-else
          title="授权运行?"
          @confirm="() => editForm(record)"
        >
          <a style="color:red;">
            禁止
          </a>
        </a-popconfirm> -->

        <a-tooltip placement="right">
          <template slot="title">
            点击改变授权状态
          </template>
          <a
            v-if="record.permissionStatus === 1"
            @click="changePermiss(record)"
          >
            授权
          </a>
          <a v-else style="color:red;" @click="changePermiss(record)">
            禁止
          </a>
        </a-tooltip>
      </div>
    </a-table>
    <change-permission ref="refcp" @create="update" />
  </div>
</template>

<script>
const columns = [
  {
    title: "编号",
    align: "left",
    width: 125,
    // dataIndex: "deviceSn",
    // ellipsis: true,
    scopedSlots: { customRender: "itemsn" },
  },
  {
    title: "单位",
    dataIndex: "customer.customerName",
    align: "left",
    width: 100,
    ellipsis: true,
  },
  {
    title: "联系人/电话",
    align: "center",
    ellipsis: true,
    width: 120,
    customRender: (text, record) => {
      if (record.liaison !== null) {
        return `${record.liaison.liaisonName}/${record.liaison.phone}`;
      }
    },
  },
  {
    title: "设备名称",
    dataIndex: "deviceModel.deviceModelName",
    align: "center",
    ellipsis: true,
    // key: 'users',
    // sorter: (a, b) => a.count - b.count
  },
  {
    title: "设备型号",
    dataIndex: "deviceModel.deviceModelType",
    align: "center",
    ellipsis: true,
    width: 120,
    // key: 'range',
    // scopedSlots: {customRender: 'rang'}
  },
  // {
  //   title: "环境湿度(%)",
  //   align: "center",
  //   ellipsis: true,
  //   customRender: (text, record) => {
  //     if (record.deviceInfos !== null) {
  //       return record.deviceInfos.envHum;
  //     }
  //   },
  // },
  // {
  //   title: "环境温度(℃)",
  //   align: "center",
  //   ellipsis: true,
  //   customRender: (text, record) => {
  //     if (record.deviceInfos !== null) {
  //       return record.deviceInfos.envTem;
  //     }
  //   },
  // },
  // {
  //   title: "发酵温度(℃)",
  //   align: "center",
  //   ellipsis: true,
  //   customRender: (text, record) => {
  //     if (record.deviceInfos !== null) {
  //       return record.deviceInfos.ferTem;
  //     }
  //   },
  // },
  {
    title: "状态",
    width: 48,
    align: "right",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "授权",
    width: 48,
    align: "right",
    scopedSlots: { customRender: "action" },
  },
];
import ChangePermission from "../../components/ChangePermission.vue";
// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
export default {
  name: "HotSearch",
  components: {
    ChangePermission,
  },
  data() {
    return {
      dataSource: [],
      columns,
      current: 1,
      pageSize: 4,
      total: 0,
      conditions: {},
    };
  },
  computed: {
    tableColumns() {
      let columns = this.columns;
      return columns.map((item) => {
        item.title = this.$t(item.key);
        return item;
      });
    },
  },
  mounted() {
    // this.update();
  },
  methods: {
    async update() {
      return await this.getDeviceList();
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onChange", e);
      const { current } = e;
      this.current = current;
      this.update();
    },
    async getDeviceList() {
      this.loading = true;
      const { current, pageSize, conditions } = this;
      // console.log('predeviceList', pageSize)
      let result = await ds.deviceList({ pageIndex: current, pageSize, ...conditions })
      console.log("getDeviceList", result);
      if (result.data.meta.success) {
        console.log("dataSource", result.data.data);
        const { list, pageNum, pageSize, total } = result.data.data.list;
        this.dataSource = list;
        console.log("dataSourceList", this.dataSource);
        this.pageIndex = pageNum;
        this.total = total ?? 0;
        this.pageSize = pageSize;
        // this.total = result.data.data.list.total
        this.loading = false;
      } else {
        // this.$message.error(result.data.meta.msg);
        this.loading = false;
        throw(result.data.meta)
      }
    },
    changePermiss(record) {
      console.log("changePermiss", record);
      this.$refs.refcp.show(record);
    },
    device_click(e) {
      console.log(e);
      this.$router.push(`/devices/detail?sn=${e.deviceSn}&id=${e.deviceId}`);
    },
    editForm(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="less" scoped>
.top-device {
  padding-bottom: 10px;
  position: relative;
  background: rgba(0, 20, 74, 0.8);
  border: 1px solid #027cc3;
  border-image: url(../../../assets/img/card-b.png) 18 round;
  border-image-width: 18px;
  box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  box-sizing: border-box;
  height: 243px;
  &-table {
    &_title {
      display: inline-block;
      width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    //   position: absolute;
    //   left: -18px;
    //   top: -18px;
    //   right: -18px;
    //   bottom: -18px;
  }
  :deep(.ant-table) {
    min-height: 190px;
  }
  :deep(.ant-empty-normal) {
    // min-height: 190px;
    background-color: #fff0;
    color: #00f8fe;
  }

  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    border-top: none;
    border-bottom-color: #fff4;
  }

  :deep(.ant-table-small) {
    // border: 1px solid #0169ad;
    border: none;
    .ant-table-thead {
      > tr {
        > th {
          color: #00f8fe;
          border-bottom: 1px solid #fff5;
        }
      }
    }
    .ant-table-tbody {
      > tr {
        > td {
          color: #fff;
          border-bottom: 1px solid #fff2;
        }
        &:hover {
          > td {
            background-color: #000000aa;
          }
        }
      }
    }
  }

  :deep(.ant-pagination) > {
    .ant-pagination-item a {
      color: #407fc6;
    }
  }
}
</style>
