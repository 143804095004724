var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', [_vm.fl_empty ? _c('a-empty') : _c('div', {
    style: "height: ".concat(_vm.height, "px"),
    attrs: {
      "id": "fr_chart_div"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };