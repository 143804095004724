import "core-js/modules/es.array.map.js";
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "FaultRank",
  data: function data() {
    return {
      fl_empty: true,
      height: 250,
      sourceData: [],
      option: null,
      myChart: null,
      resizeHandle: null,
      chartWidth: 0
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.resizeHandle = debounceTail(function () {
      return _this.resize();
    }, 299, this);
    window.addEventListener("resize", this.resizeHandle);
  },
  destroyed: function destroyed() {
    window.removeEventListener('resize', this.resizeHandle);
  },
  methods: {
    resize: function resize() {
      // console.log("fr resize");
      if (this.myChart) {
        this.myChart.resize();
        var newWidth = this.myChart.getWidth();

        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart: function init_chart(v) {
      if (!this.myChart) {
        this.myChart = echarts.init(document.getElementById("fr_chart_div"));
      }

      this.option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          minInterval: 1,
          splitLine: {
            lineStyle: {
              color: '#333'
            }
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          axisLabel: {
            inside: true,
            color: '#fff'
          },
          axisLine: {
            show: false
          },
          // axisTick: {
          //   show: false
          // },
          z: 10,
          data: []
        },
        series: [{
          type: "bar",
          data: [],
          showBackground: true,
          barWidth: 20,
          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            offset: 0,
            color: '#0c5eb2'
          }, {
            offset: 0.5,
            color: '#0d6fbb'
          }, {
            offset: 1,
            color: '#0faee1'
          }])
        }]
      };
      this.option.yAxis.data = v.map(function (i) {
        return i.id;
      });
      this.option.series[0].data = v.map(function (i) {
        return i.num;
      });
      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
    update: function update(v) {
      var _this2 = this;

      if (v === undefined || v.length === 0) {
        this.fl_empty = true;
        this.myChart = null;
        return;
      } // this.key = Date.now();


      this.sourceData = v.map(function (i) {
        return {
          id: i.errContent,
          num: i.num
        };
      });
      this.fl_empty = false;
      // this.sourceData = [
      //   { id: '故障代码#121', num: 10 },
      //   { id: '故障代码#111', num: 7 },
      //   { id: '故障代码#131', num: 15 },
      //   { id: '故障代码#134', num: 3 },
      //   { id: '故障代码#112', num: 2 },
      // ]
      this.$nextTick(function () {
        _this2.init_chart(_this2.sourceData.reverse());
      });
    }
  }
};