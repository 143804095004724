var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm._m(0);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "monitor_view"
  }, [_c('div', {
    staticClass: "container",
    attrs: {
      "id": "bmv_video"
    }
  }, [_c('div', [_vm._v("无视频")])])]);
}];
export { render, staticRenderFns };